@import '../../../../scss/theme-bootstrap';

.gnav-util-cart {
  &:hover {
    height: 50px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  &__content {
    &-errors {
      @include breakpoint($landscape-up) {
        padding: 0 44px 22px;
        font-size: 14px;
        line-height: get-line-height(12px, 14px);
      }
      ul {
        list-style: none;
        li {
          padding: 5px 0;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    &-loading {
      .gnav-util-cart.cart-loading & {
        display: block;
        opacity: 1;
      }
    }
    &-products {
      display: none;
      font-size: 14px;
      line-height: get-line-height(12px, 12px);
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
      .cart-block--nonempty.cart-loading & {
        display: none;
      }
      &-no-items {
        display: none;
        .cart-block--empty & {
          display: block;
        }
        .cart-block--empty.cart-loading &,
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &-bag-empty {
        font-family: $font--lars;
      }
      &-details {
        overflow-y: auto;
        max-height: 300px;
        line-height: get-line-height(12px, 18px);
        padding: 0 44px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-black;
        }
      }
      &-bottom {
        &-view-bag,
        &-subtotal {
          padding: 32px 0;
          border-top: 1px solid $color-cream-stroke;
          text-align: center;
          font-size: 12px;
          font-family: $font--lars;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          font-weight: 400;
          color: $color-black;
        }
        &-subtotal {
          border-bottom: 1px solid $color-cream-stroke;
          display: flex;
          justify-content: center;
        }
        &-checkout {
          padding-top: 32px;
          a.gnav-util-cart__content-products-checkout-button {
            width: 100%;
            color: $color-cream;
            &:hover {
              color: $color-cream;
            }
          }
        }
      }
    }
  }
  &.cart-block--empty {
    .gnav-util__icon__cart-count {
      display: none;
    }
  }
  .gnav-util {
    &__notification-cart,
    &__notification-store {    
      display: flex;
      justify-content: space-between;
    }
    &__notification-cart {
      background-color: $color-black;
    }
    &__notification-store {
      background-color: $color-warning;
      color: $color-black;
      margin-top: 10px;
      padding-#{$ldirection}: 20px;
      .gnav-util__notification-close {
        .icon--close {
          fill: $color-black;
        }
      }
    }
  }
}
